<template>
  <b-card-code>
    <div v-if="$can('create','Expense')" class="custom-search d-flex">
        <b-button variant="outline-primary" @click="showModal()">
          {{ $t('Add new') }}
        </b-button>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
    <div class="custom-search d-flex justify-content-start">
      <vue-excel-xlsx
          :data="getData"
          :columns="columns10"
          :filename="'filename'"
          :sheetname="'subjects'"
      >
        <b>Excel</b>
      </vue-excel-xlsx>
      <vue-json-to-csv :json-data="getData"
                       :csv-title="'My_CSV'"
      >
        <button>
          <b>CSV</b>
        </button>
      </vue-json-to-csv>
      <button @click="pdfGenerate()">
        <b>PDF</b>
      </button>
    </div>
    <!-- table -->
    <vue-good-table
        style="white-space: nowrap;text-align: center;vertical-align : middle;"
        :columns="columns"
        :rows="expenses"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex+1 }}
        </span>
         <span v-else-if="props.column.field === 'expense_type_id'">
          {{ expenseTypeName(props.row.expense_type_id) }}
        </span>
         <span v-else-if="props.column.field === 'sub_expense_type_id'">
          {{ subExpenseTypeName(props.row.sub_expense_type_id) }}
        </span>
        <span v-else-if="props.column.field === 'payment_type_id'">
          {{ paymentTypeName(props.row.payment_type_id) }}
        </span>
        <span v-else-if="props.column.field === 'bank_account_id'">
          {{ bakAccountNo(props.row.bank_account_id) }}
        </span>
        <span v-else-if="props.column.field === 'academic_year_id'">
          {{ academicYearGet(props.row.academic_year_id) }}
        </span>

        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ status[props.row.status] }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <template >
              <b-button v-if="props.row.status==0 && (findAccess(props.row.id) || user.role_id===1)" variant="primary" @click="approve(props.row.id)">
                <feather-icon class="text-body " icon="CheckIcon" size="15" />
              </b-button>
              <b-button v-if="props.row.status==0 && (findAccess(props.row.id) || user.role_id===1)" variant="danger" @click="decliend(props.row.id)">
                <feather-icon class="text-body " icon="CrosshairIcon" size="15" />
              </b-button>
              <b-button v-if="props.row.status==0 && props.row.approvalable.length<1 && user.role_id===1" variant="secondary" @click="showModal2(props.row)">
                <feather-icon class="text-body " icon="GitBranchIcon" size="15" />
              </b-button>
              <b-button v-if="props.row.status==0 && findAccess(props.row.id)" variant="secondary" @click="showModal2(props.row)">
                <feather-icon class="text-body " icon="GitBranchIcon" size="15" />
              </b-button>
              <b-button v-if="props.row.status !=2 && $can('update','Expense')"  :variant="'secondary'" @click="selectedRow(props.row)">
                <feather-icon class="text-body " icon="EditIcon" size="15" />
              </b-button>
              <b-button v-if="$can('remove','Expense')" :variant="'danger'" @click="remove(props.row.id,props.row.originalIndex)">
                <feather-icon class="text-body " icon="TrashIcon" size="15" />
              </b-button>
              </template>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['10','15','20','50','100','500']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <!-- modal login-->
    <b-modal ref="my-modal" size="lg"
             hide-footer
             :title="modal_mode==='add'?$t('Add New'):$t('Edit Information')"
    >
      <!-- form -->
      <validation-observer ref="simpleRules">
        <form ref="add_or_update_expense" style="min-height: 350px">
          <b-row>
            <b-col md="4">
              <b-card-text>
                <span>Select Expense Type </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="expense type"
                  rules="required"
              >
                <select name="expense_type_id" v-model="expense_type_id" :state="errors.length > 0 ? false:null" class="form-control">
                  <option  :value="null">Select One</option>
                  <option v-for="(expenseType, key) in active_expense_types" :value="expenseType.id" :key="key">{{expenseType.name}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>Select Sub Expense Type </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="sub expense type"
                  rules="required"
              >
                <select name="sub_expense_type_id" v-model="sub_expense_type_id" :state="errors.length > 0 ? false:null" class="form-control">
                  <option  :value="null">Select One</option>
                  <option v-for="(subExpense, key) in activeSubExpenseTypeById(expense_type_id)" :value="subExpense.id" :key="key">{{subExpense.name}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>Select Academic Year </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="academic year"
                  rules="required"
              >
                <select name="academic_year_id" v-model="academic_year_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option  :value="null">Select Academic Year</option>
                  <option v-for="(aYear, key) in active_academic_years" :value="aYear.id" :key="key">{{aYear.year}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <validation-provider
                  #default="{ errors }"
                  name="payment type"
                  rules=""
              >

                <b-form-group label="Payment Type" v-slot="{ ariaDescribedby }">
                  <b-form-radio v-for="(activePayment, key) in active_payment_types" v-model="payment_type_id" :aria-describedby="ariaDescribedby" name="payment_type_id" :value="activePayment.id" :key="key">
                    {{ activePayment.name }}</b-form-radio>
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <template v-if="payment_type_id===2">
              <b-col md="4">
                <b-card-text>
                  <span>Select Bank </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    name="bank"
                    rules="required"
                >
                  <select name="bank_id" v-model="bank_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                    <option  :value="null">Select One</option>
                    <option v-for="(activeBank, key) in active_banks" :value="activeBank.id" :key="key">{{activeBank.name}}</option>
                  </select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col md="4">
                <b-card-text>
                  <span>Select Bank Account </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    name="bank account"
                    rules="required"
                >
                  <select name="bank_account_id" v-model="bank_account_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                    <option  :value="null">Select One</option>
                    <option v-for="(bankAccount, key) in active_bank_accountById(bank_id)" :value="bankAccount.id" :key="key">{{bankAccount.account_no}}</option>
                  </select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </template>
            <template v-else-if="payment_type_id===3">
              <b-col md="4">
                <b-card-text>
                  <span>Cheque No </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    name="cheque no"
                    rules="required"
                >
                  <input type="number" v-model="check_no" name="cheque_no" placeholder="Enter cheque no here" required="required" class="form-control">
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col md="4">
                <b-card-text>
                  <span>Cheque Date </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    name="cheque date"
                    rules="required"
                >
                  <b-form-datepicker
                      v-model="cheque_date"
                      name="cheque_date"
                      type="date"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Cheque date"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </template>
            <b-col md="4">
              <b-card-text>
              <span>Amount </span>
            </b-card-text>
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  name="amount"
                  rules="required"
              >
                <b-form-input
                    v-model="amount" name="amount"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter amount here"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>Expense Date </span>
              </b-card-text>
              <validation-provider
                    #default="{ errors }"
                    name="expense date"
                    rules="required"
                >
                  <b-form-datepicker
                      v-model="expense_date"
                      name="expense_date"
                      type="date"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Expense date"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-col>
            <b-col v-if="modal_mode==='add'" md="3">
              <b-card-text>
                <span>Need Approval?</span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="need approval"
                  rules="required"
              >
                <b-form-radio-group
                    v-model="is_approved"
                    :options="options2"
                    stacked
                    name="is_approved"
                >
                </b-form-radio-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4" v-if="modal_mode==='add' && is_approved===0">
              <b-card-text>
                <span>Select Employee </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="employee"
                  rules="required"
              >
                <select v-model="employee_id" name="employee_id" class="form-control"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'">
                  <option  :value="null">Select One</option>
                  <option v-for="(employee, key) in employees" :key="key" :id="employee.id" :value="employee.id">{{ employeeNameById(employee.id) +' '+ employeeIdById(employee.id)}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="9">
              <b-card-text>
                <span>Note </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="note"
                  rules="required"
              >
                <textarea id="note" name="note" v-model="note" placeholder="write a not if needed" class="form-control"></textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <!-- submit button -->
            <b-col md="4" class="mt-2">
              <b-button
                  variant="primary"
                  type="submit"
                  @click.prevent="addNew()"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </b-modal>
    <b-modal ref="my-modal2"
             hide-footer size="lg"
             :title="'Foreword Information'"
    >
      <b-row>
        <b-col md="12">
          <b-table-simple hover small caption-top responsive>
            <b-thead>
              <b-th class="text-center"  :colspan="1">Employee ID</b-th>
              <b-th class="text-center"  :colspan="1">Employee Name</b-th>
              <b-th class="text-center"  :colspan="1">Comment</b-th>
              <b-th class="text-center"  :colspan="1">Status</b-th>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(grade,k) in selected_row.approvalable" :key="k">
                <b-th class="text-center">{{ employeeIdById(grade.employee_id) }}</b-th>
                <b-th class="text-center">{{ employeeNameById(grade.employee_id) }}</b-th>
                <b-th class="text-center">{{ grade.comment }}</b-th>
                <b-th class="text-center">{{ status3[parseInt(grade.status)] }}</b-th>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form ref="leave_request">
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    name="employee"
                    rules="required"
                >
                  <select name="employee_id" v-model="employee_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                    <option  :value="null">Select One</option>
                    <option v-for="(employee, key) in employees" :value="employee.id" :key="key">{{employeeNameById(employee.id)}}- {{employee.employee_id}}</option>
                  </select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    name="comment"
                    rules="required"
                >
                  <b-form-textarea
                      v-model="comment"
                      name="comment"
                      :state="errors.length > 0 ? false:null"
                      placeholder="comment"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- submit button -->
            <b-col>
              <b-button
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
              >
                {{ $t('Submit') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,BBadge, BPagination, BFormGroup, BFormInput, BFormSelect,BRow, BCol,
  BCardText,BFormRadioGroup,BFormDatepicker,BFormRadio,BModal,BFormTextarea,
  BForm,BTableSimple,BThead,BTr,BTh,BTbody,
} from 'bootstrap-vue'
import {required,integer} from '@validations'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";
import moment from "moment";
import VueExcelXlsx from "vue-excel-xlsx";
import VueJsonToCsv from 'vue-json-to-csv'
import Vue from "vue";
import download from "downloadjs";
Vue.use(VueExcelXlsx);
export default {
  name:'Expense',
  components: {
    BCardCode,
    VueGoodTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,BModal,BFormTextarea,
    BForm,BTableSimple,BThead,BTr,BTh,BTbody,
    BFormSelect,
    BButton,BFormRadio,
    ValidationProvider,
    ValidationObserver,
    BRow, BCol,BCardText,BFormRadioGroup,
    BFormDatepicker, VueJsonToCsv
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      moment,
      modal_mode:'add',
      expense_type_id:null,
      sub_expense_type_id:null,
      bank_id:null,
      bank_account_id:null,
      check_no:'',
      cheque_date:'',
      is_approved: 1,
      academic_year_id:null,
      payment_type_id:1,
      note:'',
      amount:'',
      expense_date:'',
      options2: [
        { text: 'Yes', value: 0 },
        { text: 'No', value: 1 },
      ],
      required,integer,
      pageLength: 10,
      dir: false,
      selected_row:{},
      columns: [
        {
          label: 'SL',
          field: 'id',
        },
        {
          label: 'Invoice No',
          field: 'invoice_no',
        },
        {
          label: 'Expense Type Name',
          field: 'expense_type_id',
        },
        {
          label: 'Sub Expense Type Name',
          field: 'sub_expense_type_id',
        },
        {
          label: 'Payment Type',
          field: 'payment_type_id',
        },
        {
          label: 'Amount',
          field: 'amount',
        },
        {
          label: 'Bank Account No',
          field: 'bank_account_id',
        },
        {
          label: 'Cheque No',
          field: 'cheque_no',
        },
        {
          label: 'Cheque Date',
          field: 'cheque_date',
        },
        {
          label: 'Expense Date',
          field: 'expense_date',
        },
        {
          label: 'Academic Year',
          field: 'academic_year_id',
        },
        {
          label: 'Note',
          field: 'note',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
      status: {
        0: 'pending',
        1: 'approved',
        2: 'canceled',
      },
      status3:{
        0:'Pending',
        1:'Approved',
        2:'Foreword',
        3:'Declined'
      },
      employee_id:null,
      comment:'',
      columns10: [
        {
          label: 'Invoice No',
          field: 'InvoiceNo',
        },
        {
          label: 'Expense Type Name',
          field: 'ExpenseTypeName',
        },
        {
          label: 'Sub Expense Type Name',
          field: 'SubExpenseTypeName',
        },
        {
          label: 'Payment Type',
          field: 'PaymentType',
        },
        {
          label: 'Amount',
          field: 'Amount',
        },
        {
          label: 'Bank Account No',
          field: 'BankAccountNo',
        },
        {
          label: 'Cheque No',
          field: 'ChequeNo',
        },
        {
          label: 'Cheque Date',
          field: 'ChequeDate',
        },
        {
          label: 'Expense Date',
          field: 'ExpenseDate',
        },
        {
          label: 'Academic Year',
          field: 'AcademicYear',
        },
        {
          label: 'Note',
          field: 'Note',
        },
        {
          label: 'Status',
          field: 'status',
        }
      ],
    }
  },
  methods:{
    expenseTypeName(id){
      let type= this.expenseTypeById(parseInt(id));
      if (type) return type.name;
      else return '';
    },
    subExpenseTypeName(id){
      let type= this.subExpenseTypeById(parseInt(id));
      if (type) return type.name;
      else return '';
    },
    paymentTypeName(id){
      let type= this.paymentTypeById(parseInt(id));
      if (type) return type.name;
      else return '';
    },
    bakAccountNo(id){
      let type= this.bank_accountById(parseInt(id));
      if (type) return type.account_no;
      else return '';
    },
    academicYearGet(id){
      return this.academic_yearByID(parseInt(id));
    },
    selectedRow(row){
      this.modal_mode='edit';
      this.selected_row=row;
      this.expense_type_id=row.expense_type_id;
      this.sub_expense_type_id=row.sub_expense_type_id;
      this.academic_year_id=row.academic_year_id;
      this.payment_type_id=row.payment_type_id;
      this.amount=row.amount;
      this.expense_date=row.expense_date;
      this.bank_id=row.bank_id;
      this.bank_account_no=row.bank_account_no;
      this.cheque_no=row.cheque_no;
      this.cheque_date=row.cheque_date;
      this.expense_date=row.expense_date;
      this.note=row.note;
      this.$refs['my-modal'].show()
    },
    approve(id){
      this.$swal({
        title: 'Are you sure?',
        text: "You want to approve!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, approve it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          apiCall.put(`/approve/expense${id}`).then((response)=>{
            this.$toaster.success(response.data.message);
            //this.$store.commit('APPROVE_EXPENSE',index);
            this.$store.dispatch('GET_ALL_EXPENSE');
          }).catch((error)=>{
            this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    decliend(id){
      this.$swal({
        title: 'Are you sure?',
        text: "You want to cancel!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          apiCall.put(`/cancel/expense${id}`).then((response)=>{
            this.$toaster.success(response.data.message);
            this.$store.dispatch('GET_ALL_EXPENSE');
          }).catch((error)=>{
            this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    remove(id,index){
      this.$swal({
        title: 'Are you sure?',
        text: "You want to remove this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          apiCall.delete(`/expense/remove${id}`).then((response)=>{
            this.$toaster.success(response.data.message);
            this.$store.commit('REMOVE_EXPENSE',index);
          }).catch((error)=>{
            this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    showModal() {
      this.model_mode='add';
      this.$refs['my-modal'].show();
      this.selected_row={};
      this.expense_type_id=null;
      this.sub_expense_type_id=null;
      this.academic_year_id=null;
      this.payment_type_id=null;
      this.amount=0;
      this.expense_date=null;
      this.bank_id=null;
      this.bank_account_no=''
      this.cheque_no='';
      this.cheque_date=null;
      this.expense_date=null;
      this.note='';
    },
    hideModal() {
      this.$refs['my-modal'].hide();
      this.modal_mode='add';
    },
    addNew(){
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData(this.$refs.add_or_update_expense);
          data.append('note',this.note);
          if(this.modal_mode==='add') {
            apiCall.post('/expense/store', data).then((response) => {
              if (response.data.status === 'success') {
                this.$toaster.success(response.data.message);
                this.$store.dispatch('GET_ALL_EXPENSE');
                this.$refs.simpleRules.reset();
                this.hideModal();
              } else {
                this.$toaster.error(response.data.message);
              }
            }).catch((error) => {
              if (error.response.status == 422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              } else this.$toaster.error(error.response.data.message);
            });
          }else {
            apiCall.post(`/expense/update${this.selected_row.id}`, data).then((response) => {
              if (response.data.status === 'success') {
                this.$toaster.success(response.data.message);
                this.$set(this.expenses,this.selected_row.originalIndex,response.data.data);
                //this.$store.commit('UPDATE_EXPENSE', [response.data.data,this.selected_row.originalIndex]);
                //this.$refs.simpleRules.reset();
                this.hideModal();
              } else {
                this.$toaster.error(response.data.message);
              }
            }).catch((error) => {
              if (error.response.status == 422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              } else this.$toaster.error(error.response.data.message);
            });
          }
        }
      })
    },
    showModal2(row) {
      this.selected_row=row;
      this.$refs['my-modal2'].show();
    },
    hideModal2() {
      this.$refs['my-modal2'].hide();
    },
    validationForm(){
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData(this.$refs.leave_request);
          apiCall.post(`/expense/info/forward${this.selected_row.id}`,data).then((response)=>{
            this.hideModal2();
            this.$toaster.success(response.data.message);
            this.selected_row={};
            this.employee_id=null;
            this.comment='';
            this.$store.dispatch('GET_ALL_EXPENSE');
          }).catch((error)=>{
            if (error.response.status == 422) this.$toaster.error(error.response.data.errors.name);
            else this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    findAccess(id){
      let ff = this.expenses.find(item=>item.id===parseInt(id));
      if(ff){
        if(ff.approvalable.length<1) return false;
        else return ff.approvalable[ff.approvalable.length -1].employee_id===this.user.userable.id;
      }
      else return false;
    },
    pdfGenerate() {
      let title = "Expense";
      let clm = ['InvoiceNo','ExpenseTypeName', 'SubExpenseTypeName', 'PaymentType', 'Amount', 'BankAccountNo', 'ChequeNo','ChequeDate', 'ExpenseDate', 'AcademicYear','Note', 'Status' ];
      let data = new FormData();
      data.append('title', title);
      data.append('columns', JSON.stringify(clm));
      data.append('data', JSON.stringify(this.getData));
      apiCall.post('/get/common/table/export/pdf', data, {responseType: 'blob'}).then((response) => {
        const content = response.headers['content-type'];
        download(response.data, 'expenses.pdf', content)
      }).catch((error) => {
        this.$toaster.error(error.response.data.message);
      });
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        0 : 'light-danger',
        1 : 'light-success',
        2 : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    user(){
      return JSON.parse(localStorage.getItem('userData'))
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ...mapGetters(['expenses','subExpenseTypeById','active_expense_types','employees','employeeIdById','employeeNameById',
      'expenseTypeById','activeSubExpenseTypeById','active_academic_years','academic_years',
      'active_payment_types','paymentTypeById','bank_accountById','academic_yearByID',
      'active_banks','active_bank_accountById','payment_types','banks','bank_accounts','expense_types','sub_expense_types']),
    getData() {
    return this.expenses.map(item => ({
      InvoiceNo: item.invoice_no,
      ExpenseTypeName: item.expense_type_id ? this.expenseTypeName(item.expense_type_id) : '',
      SubExpenseTypeName: item.sub_expense_type_id ? this.expenseTypeName(item.sub_expense_type_id) : '',
      PaymentType: item.payment_type_id ? this.paymentTypeById(item.payment_type_id) : '',
      Amount: item.amount,
      BankAccountNo: item.bank_account_id ? this.bakAccountNo(item.bank_account_id) : '',
      ChequeNo: item.cheque_no ? item.cheque_no: '',
      ChequeDate: item.cheque_date ? item.cheque_date: '',
      ExpenseDate: item.expense_date,
      AcademicYear: item.academic_year_id ? this.academic_yearByID(item.academic_year_id) : '',
      Note: item.note,
      Status: item.status == 1 ? 'Approved' : (item.status == 2 ? 'Canceled' : (!item.status ? 'Pending' : '')),
    }));
    }
  },
  created() {
    if(this.expenses.length <1) this.$store.dispatch('GET_ALL_EXPENSE');
    if(this.payment_types.length <1) this.$store.dispatch('GET_ALL_PAYMENT_TYPE');
    if(this.banks.length <1) this.$store.dispatch('GET_ALL_BANK');
    if(this.bank_accounts.length <1) this.$store.dispatch('GET_ALL_BANK_ACCOUNT');
    if(this.expense_types.length <1) this.$store.dispatch('GET_ALL_EXPENSE_TYPE');
    if(this.sub_expense_types.length <1) this.$store.dispatch('GET_ALL_SUB_EXPENSE_TYPE');
    if(this.academic_years.length <1) this.$store.dispatch('GET_ALL_ACADEMIC_YEAR');
    if(this.employees.length <1) this.$store.dispatch('GET_ALL_EMPLOYEE');
    //this.$store.dispatch('GET_ACTIVE_EXPENSE_TYPE');
    this.$store.dispatch('GET_ACTIVE_SUB_EXPENSE_TYPE');
    this.$store.dispatch('GET_ACTIVE_ACADEMIC_YEAR');
  },
  watch:{
    bank_id(){
      this.bank_account_id=null;
    },
    expense_type_id(){
      this.sub_expense_type_id=null;
    }
  }
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>